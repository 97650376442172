import { MenuItemsType } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    label: t('HOME'),
    icon: 'Swap',
    href: 'https://swirlswap.com',
    items: [],
  },
  {
    label: t('FARM'),
    icon: 'Swap',
    href: '/farms',
    items: [],
  },
  {
    label: t('SWAP'),
    icon: 'Swap',
    href: '/swap',
    items: [],
  },
  {
    label: t('STAKE'),
    icon: 'Swap',
    href: '/pools',
    items: [],
  },
]

export default config
